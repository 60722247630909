import { Button } from "@mui/material";
import { SubmitButtonStyles } from "./SubmitButton.styles";

export default function SubmitButton({ text, className = "" }) {
  const classes = SubmitButtonStyles();
  return (
    <Button
      type="submit"
      className={`${classes.root} ${className}`}
      sx={{ color: "white", borderRadius: "10px", fontWeight: 300 }}
    >
      {text}
    </Button>
  );
}
