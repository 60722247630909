import axios from "axios";
import { fallbackURL } from "../../consts/FallbackURL";
import { serviceURL } from "../../consts/ServiceURL";
import { AxiosPostBody, AxiosResponse } from "../../types/AxiosPost";
import { UserCredentialsType } from "../../types/types";
import { emit } from "../../utils/eventManager";

type HeadersType = {
  "Content-Type": "application/json";
  "x-hooli-token"?: string;
  "x-session-id"?: string;
};

export default async function axiosPost<T>(
  loginReq: "Y" | "N",
  body: AxiosPostBody,
  arg: UserCredentialsType
): Promise<AxiosResponse<T>> {
  const headers: HeadersType = {
    "Content-Type": "application/json",
  };

  if (loginReq === "Y") {
    headers["x-hooli-token"] =
      arg.hooliToken || (sessionStorage.getItem("hooliToken") as string);
    headers["x-session-id"] =
      arg.sessionId || (sessionStorage.getItem("sessionId") as string);
  }

  try {
    const result: AxiosResponse<T> = await (
      await axios.post(serviceURL, body, { headers })
    ).data;
    if (!result || result === null || result === undefined) {
      return {
        status: {
          code: 0,
          errcode: 99999,
          errmsg: "Error inesperado en request",
          action: "U",
          id: null,
        },
        result: [],
      };
    }
    if (
      result.status.errmsg === "Su sesión ha expirado por falta de actividad"
    ) {
      localStorage.clear();
      // window.location.assign(fallbackURL);
    }
    return result;
  } catch (err: any) {
    return {
      status: {
        code: 0,
        errcode: err.response ? err.response.status : 0,
        errmsg: err.message,
        action: "U",
        id: null,
      },
      result: [],
    };
  }
}
axios.interceptors.response.use(
  (response: any) => {
    console.log(
      "Estos son los interceptors",
      response,
      window.sessionStorage.getItem("hooliToken")
    );

    // if (
    //     response.data?.status?.errmsg ===
    //     "Su sesión ha expirado por falta de actividad"
    // ) {

    //     emit("sessionStorageChanged", {
    //         hooliToken: 0,
    //         sessionId: 0,
    //     });
    // }
    if (response.data?.status?.code != 1) {
      switch (response.data?.status?.action) {
        case "X": {
          if (response.data?.status?.errcode === 10000) {
            window.sessionStorage.setItem("hooliToken", "");
            window.sessionStorage.setItem("sessionId", "");
            return emit("sessionExpired", {
              route: "/login",
              message: response.data.status.errmsg,
            });
          }
          return response;
        }
        case "W": {
          if (response.data?.status?.errcode === 10000) {
            window.sessionStorage.setItem("hooliToken", "");
            window.sessionStorage.setItem("sessionId", "");
            return emit("sessionExpired", {
              route: "/login",
              message: response.data.status.errmsg,
            });
          }
          return emit("serverError", {
            message: response.data.status.errmsg,
          });
        }
        case "E": {
          if (response.data?.status?.errcode === 10000) {
            window.sessionStorage.setItem("hooliToken", "");
            window.sessionStorage.setItem("sessionId", "");
            return emit("sessionExpired", {
              route: "/login",
              message: response.data.status.errmsg,
            });
          }
          return emit("serverError", {
            message: "Error de servidor",
          });
        }
        default: {
          return response;
        }
      }
    }

    return response;
  },
  (error) => {}
);
