import { useState, useEffect, useCallback } from "react";
import { useFormik } from "formik";
import { Box, Typography } from "@mui/material";
import moment from "moment";
import Papa from "papaparse";
import * as Yup from "yup";

import {
  MerchantNominaEmpleadoGet,
  MerchantLiquidacionIns,
} from "../../../../services/hooli-services";

import {
  FormikSelectInput,
  FormikDateInput,
} from "../../../../components/utils/inputs/FormikInputs";
import XGridDemo from "../../../utils/grid/Xgrid";
import SubmitButton from "../../../../components/utils/button/SubmitButton";
import { transferenciasUpd as transferenciasTitles } from "../../../../consts/titulos-de-tablas";
import { formatNumber } from "../../../../services/Local-services";

import { removeAddedOption } from "../../utils";

import styles from "./index.module.scss";

const CreateTransferForm = ({
  payrollsOptions,
  credentials,
  classes,
  style,
  retrieveTransfers,
  handleCloseModal,
}) => {
  const [employeeList, setEmployeeList] = useState([]);
  const [firstLoad, setFirstLoad] = useState(false);
  const [disabledForm, setDisabledForm] = useState(false);

  // Función para manejar la carga del archivo
  const handleFileUpload = (event) => {
    const file = event.target.files[0];
    if (file) {
      Papa.parse(file, {
        complete: (result) => {
          updateTableFromCsv(result.data);
        },
        header: true,
        skipEmptyLines: true,
      });
    }
  };

  const updateTableFromCsv = (csvData) => {
    // csvData es un array de objetos [{ cuit, transferencia, bono }, {...}]
    const employeeCuitList = employeeList.map((employee) => ({
      cuit: employee["CUIT/CUIL"],
      id: employee.id,
      tipo: employee.merchantEmpleadoTipo,
    }));

    let updatedDetails = [...createTransferFormik.values.detalle];
    csvData.forEach((row) => {
      const { cuit, transferencia, bono } = row;

      const { id, tipo } =
        employeeCuitList.find((detail) => detail.cuit === cuit) || {};
      if (id) {
        createTransferFormik.setFieldValue(
          `transferencia_${id}`,
          transferencia
        );
        if (tipo === "Usuario Hooli")
          createTransferFormik.setFieldValue(`bono_${id}`, bono);

        const index = updatedDetails.findIndex(
          (detail) => detail.merchantNominaEmpleadoId === id
        );

        const detail =
          index > -1
            ? {
                ...updatedDetails[index],
                merchantLiquidacionMonto: Number(transferencia),
                merchantLiquidacionBono: Number(bono),
              }
            : {
                merchantNominaEmpleadoId: id,
                merchantLiquidacionMonto: Number(transferencia),
                merchantLiquidacionBono: Number(bono),
                cuit,
              };

        if (index > -1) {
          updatedDetails[index] = detail;
        } else {
          updatedDetails = [...updatedDetails, detail];
        }
      }
    });

    createTransferFormik.setFieldValue("detalle", updatedDetails);
  };

  const filteredOption = removeAddedOption(payrollsOptions);

  const createValidationSchema = (employeeList) => {
    const schemaShape = {};

    employeeList.forEach((employee) => {
      // Validación para el campo de transferencia
      schemaShape[`transferencia_${employee.id}`] = Yup.number()
        .required("Este campo es obligatorio")
        .test("max-decimals", "Ingresá hasta dos decimales", (value) => {
          console.log(value);
          if (value !== undefined && value !== null) {
            const numValue = parseFloat(value);
            if (isNaN(numValue)) return false;

            const decimalPart = numValue.toString().split(".")[1];
            console.log(!decimalPart || decimalPart.length <= 2);
            return !decimalPart || decimalPart.length <= 2;
          }
          return true;
        });

      // Validación para el campo de bono si es un Usuario Hooli
      if (employee.merchantEmpleadoTipo === "Usuario Hooli") {
        schemaShape[`bono_${employee.id}`] = Yup.number()
          .required("Este campo es obligatorio")
          .test("max-decimals", "Ingresá hasta dos decimales", (value) => {
            if (value !== undefined && value !== null) {
              const numValue = parseFloat(value);
              if (isNaN(numValue)) return false;

              const decimalPart = numValue.toString().split(".")[1];
              return !decimalPart || decimalPart.length <= 2;
            }
            return true;
          });
      }
    });

    return Yup.object().shape(schemaShape);
  };

  const validationSchema = createValidationSchema(employeeList);

  const createTransferFormik = useFormik({
    initialValues: {
      nominaId: filteredOption[0]?.merchantNominaId,
      periodo: "",
      fechaDePago: "",
      notas: "",
      detalle: [],
    },
    onSubmit: async (values, { resetForm, setSubmitting }) => {
      const isValid = await createTransferFormik.validateForm();
      if (!isValid || Object.keys(createTransferFormik.errors).length > 0) {
        setSubmitting(false);
        return;
      }
      if (!firstLoad) {
        setFirstLoad(true);
        setDisabledForm(true);
        return;
      }
      await MerchantLiquidacionIns({
        merchantNominaId: values.nominaId,
        merchantLiquidacionDetalle: JSON.stringify(
          values.detalle.map((item) => ({
            merchantNominaEmpleadoId: item.merchantNominaEmpleadoId,
            merchantLiquidacionMonto: Number(item.merchantLiquidacionMonto),
            merchantLiquidacionBono:
              Number(item.merchantLiquidacionBono) ?? undefined,
          }))
        ),
        merchantLiquidacionPeriodo: moment(values.periodo).format("MM/YYYY"),
        merchantLiquidacionFechaAcreditacion: moment(values.fechaDePago).format(
          "YYYY/MM/DD"
        ),
        merchantLiquidacionNotas: values.notas,
      });
      retrieveTransfers();
      handleCloseModal(resetForm);
    },
    validationSchema,
    validateOnChange: true,
  });

  const totalTransferAmount = createTransferFormik.values.detalle.reduce(
    (acc, current) => acc + current.merchantLiquidacionMonto,
    0
  );

  const totalBonusAmount = createTransferFormik.values.detalle.reduce(
    (acc, current) => acc + (current.merchantLiquidacionBono || 0),
    0
  );

  const retrieveEmployees = useCallback(async () => {
    const payload = {
      merchantNominaId: createTransferFormik.values.nominaId,
      merchantId: credentials.merchantId,
    };
    const result = await MerchantNominaEmpleadoGet(payload);
    const employeesMapped = employeesMapper(result);
    setEmployeeList(employeesMapped);
  }, [createTransferFormik.values.nominaId, credentials.merchantId]);

  useEffect(() => {
    if (firstLoad) {
      retrieveEmployees();
    }
  }, [createTransferFormik.values.nominaId, firstLoad, retrieveEmployees]);

  const employeesMapper = (employeesList = []) =>
    employeesList.map((employee) => ({
      "CUIT/CUIL": employee.merchantEmpleadoCUIT,
      Nombre: employee.merchantEmpleadoDenominacion,
      id: employee.merchantEmpleadoId,
      merchantEmpleadoTipo: employee.merchantEmpleadoTipo,
    }));

  useEffect(() => {
    if (!!employeeList.length) {
      employeeList.forEach((employee) => {
        createTransferFormik.setFieldValue(`transferencia_${employee.id}`, 0);
        if (employee.merchantEmpleadoTipo === "Usuario Hooli")
          createTransferFormik.setFieldValue(`bono_${employee.id}`, 0);
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [employeeList.length]);

  const createTransferData = {
    nominaId: { label: "Nómina" },
    periodo: { label: "Ingresá el período" },
    fechaDePago: { label: "Ingresa la fecha de pago" },
    notas: { label: "Notas" },
    ...employeeList.reduce((acc, employee) => {
      acc[`transferencia_${employee.id}`] = {};
      acc[`bono_${employee.id}`] = {};
      return acc;
    }, {}),
  };
  return (
    <form onSubmit={createTransferFormik.handleSubmit}>
      <Box
        sx={{
          ...style,
          height: !!employeeList.length ? "100%" : "fit-content",
          width: !!employeeList.length ? "60%" : "fit-content",
        }}
      >
        <Typography variant="h6" component="h2">
          Nueva liquidación
        </Typography>
        <div className={styles.inputsContainer}>
          <FormikSelectInput
            data={createTransferData}
            name="nominaId"
            formik={createTransferFormik}
            options={filteredOption}
            optionKey="merchantNominaId"
            optionName="merchantNominaNombre"
            optionValue="merchantNominaId"
            className={classes.input}
            disabled={disabledForm}
          />
          <FormikDateInput
            data={createTransferData}
            name="periodo"
            formik={createTransferFormik}
            className={classes.input}
            type="month"
            disabled={disabledForm}
            required
          />
          <FormikDateInput
            required
            data={createTransferData}
            name="fechaDePago"
            formik={createTransferFormik}
            className={classes.input}
            disabled={disabledForm}
          />
        </div>
        {firstLoad && (
          <>
            <textarea
              id="nota"
              name="notas"
              rows="4"
              cols="50"
              placeholder="Notas"
              value={createTransferFormik.values.notas}
              onChange={createTransferFormik.handleChange}
              className={styles.textArea}
            ></textarea>
            <div className={`row-grid ${styles.grid}`} id="grid-container">
              <XGridDemo
                rows={employeeList}
                titles={transferenciasTitles}
                withoutRowSelection
                formik={createTransferFormik}
                data={createTransferData}
              />
            </div>
          </>
        )}
        {firstLoad && (
          <div className={styles.formFooter}>
            <div className={styles.fileUploadWrapper}>
              <label for="file-upload" className={styles.fileUploadBtn}>
                Completar desde CSV
              </label>
              <input
                id="file-upload"
                type="file"
                className={styles.fileUploadInput}
                onChange={handleFileUpload}
              />
            </div>
            <div className={styles.totalLabelsContainer}>
              <h4>Totales</h4>
              <div className={styles.amountLabels}>
                <h4>Monto ARS: {"$" + formatNumber(totalTransferAmount)}</h4>
                <h4>Monto HC: {"$" + formatNumber(totalBonusAmount)}</h4>
              </div>
            </div>
          </div>
        )}
        <div
          style={{
            display: "flex",
            gap: "4rem",
            alignItems: "center",
            marginBottom: "6px",
          }}
        >
          <div
            onClick={() => handleCloseModal(createTransferFormik.resetForm)}
            style={{ cursor: "pointer" }}
          >
            Cerrar
          </div>
          <SubmitButton text="Aceptar" />
        </div>
      </Box>
    </form>
  );
};

export default CreateTransferForm;
